
.LoginContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 30px;
    width: 100%;
}

.LoginContainer form > img {
    margin-bottom: 20px;
    max-width: 250px;
    /* box-shadow: 1px 2px 3px rgba(0, 0, 0, .3); */
}

.LoginContainer form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    padding: 20px 0 10px 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.15);
}

.LoginContainer .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;
}

.LoginContainer .buttonBox {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px;
    gap: 10px;
    align-items: center;
}

.LoginContainer .buttonBox .thirdPartyLogin  {
    gap: 10px;
    text-transform: unset;
    color: rgb(92, 108, 117);
    height: 40px;
}

.LoginContainer .buttonBox .thirdPartyLogin img {
    width: 25px;
}

.LoginContainer button {
    width: 120px;
    height: 40px;
}

@media (max-height: 600px) {
    .LoginContainer form {
        margin-top: 5vh;
    }
}
@media (max-height: 450px) {
    .LoginContainer form {
        margin-top: 0;
    }
}