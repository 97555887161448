.EmpityFolder {
    /* margin: auto; */
    position: fixed;
    margin-top: 250px;
    font-size: 20px;
    text-align: center;
    margin-left: calc(50% - var(--closeSidebarWidth));
    transform: translateX(-50%);

}

.EmpityFolder img {
    height: 200px;
    margin-top: 10px;
    filter: opacity(50%);
}

.EmpityFolder p {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* @media (max-height: 515px) {
    .EmpityFolder {
        margin-top: 140px;
    }
} */