.SettingsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    margin-top: 70px;
    /* padding: 15px; */
    overflow: hidden;
    /* margin-left: calc((var(--closeSidebarWidth)) + 10px);    */
    background-color: white;
    width: fit-content;
    
}

.MuiTabs-flexContainer {
    max-width: 70vw;

}

.SettingsSubContainer {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    width: fit-content;
    overflow: auto;
    /* padding: 10px; */
}

.SettingsContainer > .SettingsSubContainer > .SettingsCardContainer {
    flex: 1;
    display: flex;
    justify-content: space-around;
    margin: 5px;
    background-color: white;
}

.SettingsContainer .SettingsSubContainer ul {
    width: 250px;
    background-color: white;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1); */
}

.SettingsContainer .SettingsSubContainer .MuiListSubheader-root {
    color: var(--font-color);
    font-size: 14px;
    padding-right: 0;
    top: auto;
    margin-top: 5px;
}

.SettingsContainer .SettingsSubContainer .MuiListItem-root {
    height: 40px;
    padding-right: 0;
}

.MuiInputBase-root.MuiInput-root::before, .MuiInputBase-root.MuiInput-root::after, .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }


