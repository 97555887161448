.BottomNav {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    overflow: hidden;
}

.BottomNav .MuiBottomNavigation-root {
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
    width: 100%;
}

.BottomNav .MuiBottomNavigation-root svg {
    font-size: 30px;
}

.BottomNav .MuiBottomNavigationAction-root {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media (min-width: 750px) {
    .BottomNav {
        visibility: hidden;
        height: 0;
    }
}

@media (max-width: 500px) {
    .BottomNav .MuiBottomNavigation-root {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}