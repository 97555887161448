.upper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upper-container h2 {
    min-width: 100px;
}

.task-details-container {
    /* background-color: #444; */
    margin: 8px 0;
    padding: 15px 20px;
    display: flex;
    border-radius: 5px;
    color: #eee;
    flex-direction: column;
    height: 60px;
}

.task-details-container h2 {
    font-size: 1.2rem;
    color: #eee;
}

.back-button-container {
    /* width: 100%; */
    height: 40px;
    margin: 15px 0;
    /* display: flex; */
    /* justify-content: flex-end; */
}

.back-button {
    height: 40px;
    width: 115px;
}

.task-input-detail {
    /* height: 100px; */
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    flex: 2;
    background-color: var(--color0);
    /* color: #eee; */
    font-size: 1rem;
    box-sizing: border-box;
    resize: none;
    text-transform: capitalize;
}