.MainContainer {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    margin-top: 50px;
    /* padding-bottom: 15px; */
    /* overflow: auto; */
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* height: max-content; */
    /* margin-left: calc((var(--closeSidebarWidth)) + 30px); */

}

.MainContainer .MainSubContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin-top: 50px; */
    height: fit-content;
    /* transform: translateX(calc(var(--closeSidebarWidth)/2 + var(--scroll)/2)); */
}

.MainContainer .MainSubContainer .FinancialWorksheetMainContainer {
    margin-top: 0px;
}

.MainContainer .FinancialWorksheetMainContainer .SubContainer {
    transform: none;
    width: 100%;
}

.MainContainer .MainSubContainer .TaskListContainer {
    width: 100%;
}

.MainContainer .MainSubContainer .TaskListContainer .TaskListHeader {
    padding-top: unset;
    margin-top: unset;
}

.MainContainer .MainSubContainer .TaskListContainer .TaskList .task-container {
    text-wrap: nowrap;
}

.MainContainer .FinancialWorksheetMainContainer .TableContainer ul {
    /* margin: auto; */
    width: 100%;
}

.MainContainer ul {
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1); */
    width: 300px;
    background-color: white;

}

.MainContainer .MuiListSubheader-root {
    color: var(--font-color);
    text-align: center;
    /* font-size: 26px; */
    /* font-weight: bold; */
    margin: 10px 20px 0 20px;
}

/* @media (max-width: 600px), (max-height: 600px) {
    .MainContainer {
        padding-top: 0;
    }
} */
/* 
@media (max-width: 850px) {
    .MainContainer .MainSubContainer {
        transform: translateX(calc(var(--closeSidebarWidth)/2 + var(--scroll)/2));
    }
} */

@media (max-width: 750px) {

    .MainContainer {
        transform: translateX(var(--closeSidebarWidth)/2);
    }

    .MainContainer .MainSubContainer {
        display: unset;
        gap: 0px;
    }

    /* .MainContainer .FinancialWorksheetMainContainer {
        margin-top: 0px;
        padding-top: 0px;
    } */

    .MainContainer .MainSubContainer .FinancialWorksheetMainContainer {
        margin-top: -50px;
    }

    .MainContainer .MainSubContainer .TaskListContainer {
        height: fit-content;
        width: 350px;
    }

    .MainContainer .TaskListContainer .TaskListHeader {
        margin-top: 10px;
        padding-top: 0px;
    }

}

@media (max-height: 600px) {
    .MainContainer .MainSubContainer .FinancialWorksheetMainContainer {
    margin-top: -50px;
    }
}
