.FinancialWorksheetMainContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    margin-top: 50px;
    padding-top: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    text-wrap: nowrap;
    max-width: calc(var(--vw, 1vw) * 99.5);
}

.FinancialWorksheetMainContainer .Header {
    text-align: center;
    /* text-wrap: nowrap; */
}

.FinancialWorksheetMainContainer .SubContainer {
    display: flex;
    flex-direction: row;
    /* margin: 20px; */
    transform: translateX(calc(var(--closeSidebarWidth)*-1 - 6px));
    gap: 50px;
}

.FinancialWorksheetMainContainer ul {
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1); */
    width: auto;
    background-color: white;
}

.FinancialWorksheetMainContainer .MuiListSubheader-root {
    color: var(--font-color);
    text-align: center;
    /* font-size: 26px; */
    /* font-weight: bold; */
    margin: 10px 20px 0 20px;
    position: relative;
}

.FinancialWorksheetMainContainer .MuiListItemText-root {
    overflow: hidden;
}
/* 
@media (max-width: 600px), (max-height: 600px) {
    .FinancialWorksheetMainContainer {
        padding-top: 70px;
    }
} */

/* .FinancialWorksheetMainContainer .TableContainer table {
    margin-top: 50px;
    font-size: 1.2em;
    width: 100%;
    background-color: white;
    border-radius: 5px;
}

.FinancialWorksheetMainContainer .TableContainer table th {
    font-size: 1.3em;
}

.FinancialWorksheetMainContainer .TableContainer table thead tr {
    background-color: var(--color0);
    padding: 10px;

}
.FinancialWorksheetMainContainer .TableContainer table tr:nth-child(even) {
    background-color: var(--color0);
}

.FinancialWorksheetMainContainer .TableContainer table td, th {
    padding: 10px;
    min-width: 100px;
}  */

 /* Calendar stuff */
.FinancialWorksheetMainContainer .CalendarHeader {
    text-align: center;
    /* margin-top: 50px; */
    margin-bottom: 10px;
    /* padding-top: 50px; */
}

.FinancialWorksheetMainContainer .Calendar {
    position: relative;
    right: unset;
    top: unset;
    /* margin-top: 50px;
    padding-top: 50px; */
}

.FinancialWorksheetMainContainer .react-calendar {
    width: min-content;
    box-shadow: none;
    border-radius: 5px;
    margin: auto;
}

.FinancialWorksheetMainContainer .react-calendar__year-view,
.FinancialWorksheetMainContainer .react-calendar__decade-view,
.FinancialWorksheetMainContainer .react-calendar__century-view {
    width: 350px;
}

.FinancialWorksheetMainContainer .react-calendar__year-view .react-calendar__tile,
.FinancialWorksheetMainContainer .react-calendar__decade-view .react-calendar__tile,
.FinancialWorksheetMainContainer .react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}

.FinancialWorksheetMainContainer .react-calendar__tile--now,
.FinancialWorksheetMainContainer .react-calendar__tile--now:enabled:hover,
.FinancialWorksheetMainContainer .react-calendar__tile--now:enabled:focus,
.FinancialWorksheetMainContainer .react-calendar__tile:enabled:hover {
    background: none;
}

@media (max-height: 600px) {
    .FinancialWorksheetMainContainer {
        margin-top: 0;
    }
}

@media (max-width: 900px) {
    .FinancialWorksheetMainContainer .SubContainer {
        gap: 10px;
        transform: none;
    }
}

@media (max-width: 750px) {
    .FinancialWorksheetMainContainer {
        margin-top: 0;
    }

    .FinancialWorksheetMainContainer .SubContainer {
        flex-direction: column;
    }
}