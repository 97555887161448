.ItensContainer {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 90px;
}

.ItensContainer::-webkit-scrollbar {
    width: var(--closeSidebarScrollWidth);
}

.Sidebar .MuiList-padding {
    height: 100vh;
    overflow: hidden;
    padding-top: 48px;
    white-space: nowrap;
    transition: .3s;
    display: flex;
    flex-direction: column;
}

.Sidebar .ItensContainer .MuiButtonBase-root {
    padding-left: 5px;
    gap: 10px;
    height: 60px;
}

.Sidebar .ItensContainer .MuiButtonBase-root .MuiListItemIcon-root {
    padding-left: 5px;
    min-width: unset;
}

.Sidebar .ItensContainer .MuiButtonBase-root .MuiListItemText-root span {
    transform: translate(2px,2px);
}