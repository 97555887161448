.Summary {
    display: flex;
    /* height: calc((var(--vh, 1vh) * 100) - 70px); */
    /* padding: 0 10px 0 0px; */
    /* margin-bottom: 55px; */
    height: 100%;
    overflow: hidden;
    gap: 5px;
    /* width: inherit; */
    flex-direction: column;
    /* box-shadow: var(--box-shadow); */
}

@media (max-width: 750px) {
    .Summary {
        margin-bottom: 115px;
    }
}

.Summary .MuiList-root {
    display: flex;
    flex-direction: row;
    height: min-content;
    /* background-color: white; */
    padding: 0;
    border-radius: 2px;
    /* margin-top: 10px; */
    /* margin: 0 5px; */
    gap: 5px;
    /* position: fixed; */
    /* width: -webkit-fill-available; */
}

.Summary .MuiList-root .MuiButtonBase-root {
    border-radius: 2px;
    background-color: white;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    /* margin: 5px; */
    box-shadow: var(--box-shadow);
}

.Summary .MuiList-root .MuiButtonBase-root li {
    background-color: white;
}

.Summary .MuiList-root .MuiButtonBase-root span {
    font-size: 20px;
    font-weight: 600;
    color: var(--font-color);
}

.chartContent {
    background-color: white;
    /* padding: 10px 10px 0 0; */
    /* padding-right: 5px; */
    /* margin: 0 5px; */
    /* min-width: 70vw; */
    border-radius: 2px;
    box-shadow: 0px 0px 5px #ccc;
    /* height: 100%; */
    /* min-height: 60vh; */
    /* height: inherit; */
    /* width: 100%; */
    height: 100%;
    overflow: hidden;
}

.chartContent .chartSubContent {
    width: var(--graphWidth) !important;
    /* height: calc(100% - 10px); */
    height: 100%;
    overflow: hidden;
}

@media (max-width: 750px) {
    /* .Summary {
        flex-direction: row;
    } */

    /* .chartContent {
        margin: 0 5px;
    } */
/* 
    .Summary .MuiList-root {
    flex-direction: column;
    } */
}
/* 
@media (max-width: 600px) {
    .chartContent {
        min-width: 420px;
    }
} */

@media (max-width: 500px) {
    .Summary .MuiList-root .MuiButtonBase-root span {
        font-size: 16px;
    }
}