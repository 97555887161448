.react-calendar {
  width: 550px;
  max-width: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: capitalize;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 70px;
  background: none;
  font-size: 25px;
  font-weight: 600;
}
/* .react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
} */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation__label__labelText {
  text-transform: uppercase;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  text-decoration: none;
}
.react-calendar__month-view__weekdays abbr[title] {
    text-decoration: underline;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #adadad;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #adadad;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 20px 0;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 100%;
}
.react-calendar__tile .amount {
  font-size: 12px;
  font-weight: 300;
  height: 1rem;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: radial-gradient(#e6e6e6 50%,white 70%);
} */
.react-calendar__tile--now {
  background: radial-gradient(#d5d5d5 50%,white 70%);
}
/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: radial-gradient(#8cc5ff 50%,white 70%);
} */
/* .react-calendar__tile--hasActive {
  background: #76baff;
} */
/* .react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
} */
/* .react-calendar__tile--active {
  background: #006edc;
  color: white;
} */
/* .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
} */
/* .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
} */
