@keyframes scaleAnimationIn2 {
    0% {
        opacity: .3;
        transform: translate(0, -50%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.Calendar {
    z-index: 10;
    position: fixed;
    right: 10px;
    top: 130px;
    /* animation: scaleAnimationIn2 .2s; */
}

@media (max-width: 700px) {
    .Calendar {
        right: 0px;
    }
}

@media (max-width: 550px) {
    .Calendar .react-calendar {
        width: 100vw;
    }

    .Calendar .react-calendar .react-calendar__tile {
        padding: 3.5vw 0;
    }
   
    .react-calendar__tile {
        font-size: 18px;
    }

    .react-calendar__month-view__weekdays abbr[title] {
        font-size: 14px;
    }

    .react-calendar__navigation button {
        font-size: 23px;
    }

    .react-calendar__tile .amount {
        font-size: 11px;
    }
}

@media (max-width: 400px) {
    .react-calendar__navigation button {
        font-size: 20px;
    }

    .Calendar .react-calendar .react-calendar__tile {
        padding: 2.5vw 0;
    }
}