.InsideSidebar .InsideSidebarItensContainer {
    overflow-x: hidden;
    overflow-y: auto;
    /* margin-bottom: 90px; */
}

.InsideSidebar .InsideSidebarItensContainer .InsideSidebarTitle {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    transform: translate(-1px,2px);
}

.ItensContainer::-webkit-scrollbar {
    width: var(--closeSidebarScrollWidth);
}

.InsideSidebar {
    width: min-content;
    margin-left: var(--closeSidebarWidth);
}

.InsideSidebar .MuiList-padding {
    /* height: 100vh; */
    overflow: hidden;
    margin-top: 45px;
    white-space: nowrap;
    transition: .3s;
    display: flex;
    flex-direction: column;
    /* margin-left: 5px; */
}

.InsideSidebar .MuiListSubheader-root {
    background-color: inherit;
}

.InsideSidebar .MuiIconButton-root {
    /* background-color: black; */
    /* border-radius: 0; */
    margin: 0 3px;
}

.InsideSidebar .MuiButtonBase-root.MuiListItemButton-root {
    padding-left: 0;
    gap: 5px;
}

.InsideSidebar .MuiButtonBase-root .MuiListItemIcon-root {
    padding-left: 5px;
    min-width: 40px;
}

.InsideSidebar .MuiButtonBase-root .MuiListItemText-root span {
    transform: translateY(2px);
}

.InsideSidebar .MuiButtonBase-root.outterButton {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-right: 3px;
}

/* .InsideSidebar .MuiButtonBase-root.innerButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
} */

.InsideSidebar .MuiButtonBase-root.innerButton svg {
    margin-left: 10px;
}
