.input-contain{
    position: relative;
}
.input{
    height: 37px;
    width: 127px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}
.input:hover{
    outline: none;
    border-color: black;
}
.input:focus{
    outline: none;
    height: 35px;
    width: 126px;
    border: 2px solid #1976D2;
    padding: 0 0 0 9px;
}
.input:focus + .placeholder-text .text, :not(.input[value=""]) + .placeholder-text .text{
    background-color: white;
    font-size: 12px;
    color: #666666;
    transform: translate(0, -140%);
}
.input:focus + .placeholder-text .text{
    /* border-color: #1976D2; */
    color: #1976D2;
}
.placeholder-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid transparent;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    align-items: center;
}
.text{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1rem;
    border-left: 5px solid white;
    border-right: 5px solid white;
    padding: 0;
    background-color: transparent;
    transform: translate(0);
    color: rgba(0, 0, 0, 0.6);
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
}
.input, .placeholder-text{
    font-size: 1rem;
    padding: 0 0 0 10px;
}

/* @media (max-width: 40rem) {
    .input{
        width: 70vw;
    }
} */

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }


  @media (max-width: 1400px) {
    .input-contain {
    width: 17vw;
    }
    .input {
    width: auto;
    }

    .input:focus{
    width: auto;
    }
}
@media (max-width: 850px) {
    .input-contain {
    width: 27vw;
    }
}
@media (max-width: 500px) {
    .input-contain {
    width: 80vw;
    }
}