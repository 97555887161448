
/* @media (max-width: 750px) {
    .AppBar h1 {
        font-size: 1.4em;
    }
}

@media (max-width: 400px) {
    .AppBar h1 {
        font-size: 1.2em;
    }
} */

.AppBar .MuiToolbar-root {
    padding-right: 10px;
    padding-left: 15px;
}

.AppBar .MuiToolbar-root h1 {
    width: 100vw;
    left: 0;
    /* padding-left: 10px; */
    text-align: center;
    position: absolute;
    z-index: -1;
}

.AppBar .MuiToolbar-root .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
}

.AppBar .MuiToolbar-root .MuiSelect-select:focus {
    background-color: initial;
}
.AppBar .MuiToolbar-root .MuiInputBase-root .MuiSvgIcon-root {
    color: white;
}

.AppBar .MuiToolbar-root .MuiSelect-select img {
    width: 20px;
    height: 15px;
}

/* .MuiPaper-root .MuiList-root .MuiMenuItem-root {
    justify-content: center;
} */

.AppBar .InnerSettingsButton {
    display: none;
}

@media (max-width: 500px) {
    .MuiAppBar-root {
        max-height: 56px;
        line-height: 1.5em;
    }
    .MuiAppBar-root h1 {
        font-size: 28px;
    }

    .AppBar .MuiToolbar-root {
        padding-right: 0px;
    }

    .AppBar .OutterSettingsButton {
        display: none;
    }
    .AppBar .InnerSettingsButton {
        display: unset;
    }
}

.AppBar .MuiBox-root {
    display: flex;
    flex-direction: row;
}

.AppBar .MuiBox-root svg {
    font-size: 28px;
}

.AppBar .MuiButtonBase-root {
    z-index: 999;
}

.AppBar .MuiBox-root .MuiButtonBase-root {
    border-radius: 0;
    height: 48px;
    width: 48px;
}

.AppBar .MuiBox-root .MuiButtonBase-root:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0;
}

/* .AppBar .MuiBox-root .MuiAvatar-root, .Panel.MuiCard-root .userContainer .MuiAvatar-root {
    background-color: var(--avatarColor);
} */

.AppBar.MuiPaper-root.MuiAppBar-root.MuiAppBar-colorPrimary {
    background-color: var(--navbar-color);
    box-shadow: none;
}

.AppBar .MuiBox-root .MuiAvatar-root {
    cursor: pointer;
    height: 35px;
    width: 35px;
}

#AppBarTooltip p {
    font-size: 14px;
    color: #bdc1c6;
    line-height: 16px;
}

.Panel.MuiCard-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: fixed;
    right: 0;
    top: 50px;
    width: 250px;
    /* padding: 5px; */
    -webkit-box-shadow: 0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08);
    box-shadow: 0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08);
}

.Panel.MuiCard-root .userContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.Panel.MuiCard-root .userContainer .MuiAvatar-root {
    height: 100px;
    width: 100px;
    font-size: 50px;
    margin: 10px;
    cursor: auto;
}

.Panel.MuiCard-root .userContainer h2 {
    font-size: 20px;
    letter-spacing: .8px;
}

.Panel.MuiCard-root .userContainer p {
    font-size: 14px;
    color: #5f6368;
    letter-spacing: .2px;
}

.Panel.MuiCard-root .userContainer .MuiIconButton-root {
    position: absolute;
    right: 0;
    top: 0;
}

/* .Panel.MuiCard-root .userContainer .MuiIconButton-root svg {
    font-size: 30px;
    color: #3c4043;
} */

.Panel.MuiCard-root .MuiDivider-root {
    width: 100%;
}

.Panel.MuiCard-root .MuiButton-root {
    color: #3c4043;
    margin: 15px;
    padding: 5px;
    border-color: #dadce0;
    text-transform: none;
    width: 100px;
    font-size: 16px;
}

.Panel.MuiCard-root .MuiButton-root:hover {
    background-color: #f7f8f8;
}

