.task-container {
    background-color: var(--color0);
    margin: 8px 0;
    padding: 5px 20px;
    display: flex;
    border-radius: 5px;
    /* color: #eee; */
    justify-content: space-between;
    align-items: center;
}

.task-title {
    cursor: pointer;
}

.buttons-container {
    /* min-width: 30px; */
    display: flex;
    justify-content: flex-end;
}

.remove-task-button,
.see-task-detail-button {
    display: flex;
    background-color: var(--color0);
    border: none;
    font-size: 24px;
    color: #1976d2;
    cursor: pointer;
    margin: 0 5px;
    align-items: center;
}
