.add-task-container {
    margin: 15px 0;
    width: 100%;
    display: flex;
}

.add-task-input {
    width: 100%;
}

.add-task-button-container {
    margin-left: 10px;
    flex: 1;
}

.add-button {
    height: 40px;
    width: 115px;
}